/*  Adobe Fonts */

@import url("https://use.typekit.net/hib2uvd.css");
@import url("https://use.typekit.net/adb6fyz.css");
/*  TailwindCSS */

@tailwind base;
@tailwind components;
@tailwind utilities;

/*  Global Colors */

:root {
  --stroke-dark: #121212;
  --stroke-dark-fill: #ffffff;
  --stroke-beige-fill: #e9e9e9;
}

/*  Navigation  */

/* nav {
  mix-blend-mode: difference;
} */

/*  Home  */

#home h1 {
  background: linear-gradient(
    to right,
    rgb(217, 70, 239),
    hsl(217, 91%, 60%),
    rgb(228, 77, 77),
    rgb(0, 187, 255),
    rgb(251, 146, 60)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#home h2 {
  color: var(--stroke-beige-fill);
}

.amsterdam {
  background: linear-gradient(145deg, #72f, #c1b);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.marquee {
  display: flex;
  align-items: center;
  cursor: -webkit-grab;
}

.marqueeText {
  font-size: 8rem;
  letter-spacing: -0.05em;
  padding-right: 0.25em;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  background: linear-gradient(
    to right,
    rgb(217, 70, 239),
    hsl(217, 91%, 60%),
    rgb(228, 77, 77),
    rgb(0, 187, 255),
    rgb(251, 146, 60)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.drag {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

@media only screen and (max-width: 768px) {
  .marqueeText {
    font-size: 4rem !important;
  }
}

/* Animated SVG */

#windmill {
  display: block;
  margin: 0 auto;
  max-width: 700px;
}

.cls-3 {
  transform: translateX(150px);
  animation: scroll 20s infinite linear;
}

@keyframes scroll {
  from {
    transform: translateX(-50px);
  }
  to {
    transform: translateX(150px);
  }
}

/*  Services Intro  */

#services-intro h2 {
  background: linear-gradient(145deg, #00bbff, #3b82f6);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#services-intro span {
  background: var(--stroke-dark-fill);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#services-intro .story {
  background: linear-gradient(to right, rgb(251, 113, 133), rgb(251, 146, 60));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/*  Services  */

#services h2,
#services button span {
  background: linear-gradient(to right, rgb(251, 113, 133), rgb(251, 146, 60));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#services h3 {
  /* background: hsl(221, 39%, 11%); */
  background: linear-gradient(300deg, #f3f, #1978d1, #3b82f6);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#services p {
  font-family: paralucent, sans-serif !important;
  font-weight: 300;
  font-style: normal;
}
#services li {
  font-family: paralucent, sans-serif !important;
  font-weight: 300;
  font-style: normal;
}

#services hr {
  color: #ffffff !important;
}

/*  Tech Stack  */

#tech-stack h2 {
  background: linear-gradient(145deg, #00bbff, #3b82f6);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/*  Footer  */

#footer {
  background: linear-gradient(
    300deg,
    rgba(82, 93, 255, 0.914),
    rgba(252, 255, 119, 0.82),
    rgba(153, 255, 151, 0.624),
    rgba(97, 170, 255, 0.72),
    rgba(35, 128, 180, 0.867),
    rgba(82, 93, 255, 0.914),
    rgb(207, 66, 202),
    rgb(255, 89, 89),
    rgb(255, 89, 89),
    rgb(251, 146, 60),
    rgb(217, 70, 239),
    hsl(217, 91%, 60%),
    rgb(228, 77, 77)
  );
  background-size: 240% 240%;
  animation: gradient-animation 20s ease infinite;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Contact Form */

#form-container h3 {
  background: linear-gradient(145deg, #72f, #c1b);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#contact .label {
  color: #444;
}

#contact textarea {
  color: var(--stroke-beige-fill);
}

.email {
  background: linear-gradient(to right, rgb(251, 113, 133), rgb(251, 146, 60));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
